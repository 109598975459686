import * as React from 'react'
import UserLoggedInterface from "../types/User/UserLoggedInterface"
import { AuthContext } from '../App'
import AuthContextType from "../types/AuthContext/AuthContextType"
import { Action, ClientContext } from "react-fetching-library"
import { toast } from "react-toastify"
import Response from "../types/Response/Response"
import { getApiUrlFromRelativeUrl, request } from "../utils/api/ApiUtil"
import AuthType from "../types/AuthContext/AuthType"
import { useNavigate } from "react-router-dom"

interface Props {
    children?: React.ReactNode,
}

export default function AuthProvider(props: Props): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    // state
    const [userLogged, setUserLogged] = React.useState<UserLoggedInterface | null>(null)
    const [jwtToken, setJwtToken] = React.useState<string | null>(null)
    const [refreshToken, setRefreshToken] = React.useState<string | null>(null)
    const [jwtExpiresAtTimestamp, setJwtExpiresAtTimestamp] = React.useState<number | null>(null)
    // client context
    const clientContext = React.useContext(ClientContext)
    const navigate = useNavigate()

    // actions
    const validateJWTTokenAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/validate-jwt-token'),
        headers: {
            Authorization: 'Bearer ' + jwtToken,
            Accept: 'application/json'
        }
    }

    const getUserLoggedAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user-info'),
        headers: {
            Authorization: 'Bearer ' + jwtToken,
            Accept: 'application/json'
        }
    }

    const refreshJwtTokenAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl('/api/refreshToken'),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: {},
    }

    //-------------- refresh jwt token -------------------
    const refreshJWTToken = async (): Promise<void> => {
        if (null !== refreshToken && undefined !== refreshToken) {
            const refreshJwtTokenAction2 = {
                ...refreshJwtTokenAction,
                body: {
                    refreshToken: refreshToken
                },
            }
            const {payload, error, status} = await request(refreshJwtTokenAction2, clientContext)
            if (error) {
                if (status === 400) {
                    // refresh token is not valid
                    logout(true)
                }
                if (status === 401) {
                    // user with this refresh token does not exist
                    logout(true)
                }
                if (status === 500) {
                    toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                }
            }
            if (payload) {
                setJwtToken(payload.jwtToken)
                setRefreshToken(payload.refreshToken)
                setJwtExpiresAtTimestamp(payload.jwtExpiresAtTimestamp)
                localStorage.setItem('auth', JSON.stringify({
                    token: payload.jwtToken,
                    refresh_token: payload.refreshToken,
                    jwtExpiresAtTimestamp: payload.jwtExpiresAtTimestamp,
                }))
            }
        }
    }

    //-------------- sign in, logout -------------------
    const signIn = (payload: AuthType): void => {
        setJwtToken(payload.token)
        setRefreshToken(payload.refresh_token)
        setJwtExpiresAtTimestamp(payload.jwtExpiresAtTimestamp)
        localStorage.setItem('auth', JSON.stringify(payload))
    }

    const logout = (isInactivity: boolean = false): void => {
        // clear window location history - to prevent user from going back to secured pages
        window.location.replace('/')
        // logout user
        localStorage.removeItem('auth')
        setJwtToken(null)
        setRefreshToken(null)
        setJwtExpiresAtTimestamp(null)
        setUserLogged(null)
        if (isInactivity) {
            navigate('/logged-out-for-inactivity')
        }
    }

    const isUserSignedIn = async (): Promise<boolean> => {
        return (jwtToken !== null) && await isJWTValid(jwtToken)
    }

    //-------------- is jwt valid, validate jwt token -------------------
    const isJWTValid = async (jwt: string | null): Promise<boolean> => {
        if (jwt === null) {
            return false
        }
        const data = await validateJWT(jwt)
        return data.success
    }

    const validateJWT = async (jwt: string | null): Promise<Response> => {
        if (jwt === null) {
            return {
                success: false,
                errors: ['JWT token is not valid'],
                data: [],
            }
        }
        const {payload, error, status} = await request(validateJWTTokenAction, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        }
        if (status === 401) {
            // expired jwt
            await refreshJWTToken()
        }
        if (payload.isValid) {
            return {
                success: true,
                errors: [],
                data: [],
            }
        }
        return {
            success: false,
            errors: ['JWT token is not valid'],
            data: [],
        }
    }

    // update userLogged
    const updateUserLogged = async (): Promise<void> => {
        const data = await getUserLogged()
        setUserLogged(data)
    }

    const getUserLogged = async (): Promise<UserLoggedInterface | null> => {
        if (jwtToken === null) {
            return null
        }
        const { payload, error, status} = await request(getUserLoggedAction, clientContext)

        if (error) {
            if (status === 401) {
                // expired jwt
                await refreshJWTToken()
            } else {
                logout()
            }
        } else {
            setUserLogged(payload)
        }
        if (status === 401) {
            logout()
        }
        return payload
    }

    // on mount (ma sa vykonat iba raz)
    React.useEffect(() => {
        const auth = localStorage.getItem('auth')
        if (auth !== null) {
            const authParsed = JSON.parse(auth)
            setRefreshToken(authParsed.refresh_token)
            setJwtExpiresAtTimestamp(authParsed.jwtExpiresAtTimestamp)
            setJwtToken(authParsed.token)
        }
    }, [])

    React.useEffect(() => {
        const getUserLoggedAsync = async () => {
            if (jwtToken === null) {
                return
            }
            if (!await isJWTValid(jwtToken)) {
                await refreshJWTToken()
            }
            await getUserLogged()
        }
        getUserLoggedAsync()
    }, [jwtToken])

    // counter to check if jwt is 5 minutes before expiring and needs to be refreshed
    React.useEffect(() => {
        if (jwtToken === null) {
            return
        }
        const interval = setInterval(async () => {
            if (jwtExpiresAtTimestamp !== null) {
                const currentTime = Date.now();
                const fiveMinutesBeforeExpiry = jwtExpiresAtTimestamp - 5 * 60 * 1000; // 5 minutes in milliseconds
                // if current time is less than or equal to 5 minutes before expiry, refresh jwt token
                if (currentTime <= fiveMinutesBeforeExpiry) {
                    await refreshJWTToken()
                }
            }
        }, 60000 /* 1 minute */)
        // cleanup on context unmount
        return () => clearInterval(interval)
    }, [jwtToken, jwtExpiresAtTimestamp])

    // auth context
    const authContext: AuthContextType = {
        userLogged,
        setUserLogged,
        jwtToken,
        setJwtToken,
        signIn,
        isUserSignedIn,
        isJWTValid,
        getUserLogged,
        logout,
        updateUserLogged,
        refreshJWTToken,
    }

    // render
    return (
        <AuthContext.Provider value={authContext}>
            {props.children}
        </AuthContext.Provider>
    )
}
