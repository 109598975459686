import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AdvertisementInterface } from '../../types/Advertisement/AdvertisementInterface'
import { Action, ClientContext } from "react-fetching-library"
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { DocumentInterface } from '../../types/Document/DocumentInterface'
import AdvertisementContactPersonForm from '../../components/advertisementContactPerson/AdvertisementContactPersonForm'
import { Box, Card, CardContent, Container, Grid, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { Share } from '@mui/icons-material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Packages from "../../components/package/Packages"
import { AuthContext } from "../../App"
import LeafletMap from "../../components/map/LeafletMap"
import ToppedAdvertisements from "../../components/toppedAdvertisement/ToppedAdvertisements"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import Loading from "../../components/loader/Loading"
import { FaTimesCircle } from "react-icons/fa"
import ConfirmModal from "../../components/modal/ConfirmModal"
import Carousel from 'react-multi-carousel'
import DeleteIcon from "@mui/icons-material/Delete"
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { getDocumentName } from "../../utils/advertisement/advertisementDocumentUtil"
import ErrorComponent from "../../components/error/ErrorComponent"
import auta_kategoria from "../../assets/icons/auta_kategoria.svg"
import nehnutelne_veci from "../../assets/icons/nehnutelne_veci.svg"
import iny_majetok_modre from '../../assets/icons/iny_majetok_modre.png'
import my_profile_photo from '../../assets/icons/my_profile_photo.svg'
import EditIcon from "@mui/icons-material/Edit"

enum AdvertisementThingType {
    MOVABLE = 1,
    IMMOVABLE = 2,
    OTHER = 3
}

const getSubject = (subject: string): string => {
    // max 35 chars then add '...' and end on first possible space
    if (subject.length > 15) {
        return subject.substr(0, 15).concat('...')
    }
    return subject
}

export default function ShowAdvertisementPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const PHOTOGRAPH_SUCCESSFULLY_DELETED: string = 'Fotografia úspešne vymazaná!'
    const DOCUMENT_SUCCESSFULLY_DELETED: string = 'Dokument úspešne vymazaný!'
    const DELETE_ADVERTISEMENT_SUCCESS_MESSAGE: string = 'Inzerát úspešne zmazaný!'
    const CANNOT_DELETE_ADVERTISEMENT: string = 'Nemáte oprávnenie na zmazanie inzerátu!'
    const CANNOT_EDIT_ADVERTISEMENT: string = 'Nemáte oprávnenie na úpravu inzerátu!'
    // state
    const [advertisement, setAdvertisement] = React.useState<AdvertisementInterface | null>(null)
    const [activePhotoIndex, setActivePhotoIndex] = React.useState(0)
    const [startIndex, setStartIndex] = React.useState(0)
    const [visibleThumbnails, setVisibleThumbnails] = React.useState(7)
    const [lightboxOpen, setLightboxOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [photographDeleteModalShow, setPhotographDeleteModalShow] = React.useState<boolean>(false)
    const [photographToDelete, setPhotographToDelete] = React.useState<DocumentInterface | null>(null)
    const [documentItemDeleteModalShow, setDocumentItemDeleteModalShow] = React.useState<boolean>(false)
    const [documentItemToDelete, setDocumentItemToDelete] = React.useState<DocumentInterface | null>(null)
    const [showDeleteAdvertisementModal, setShowDeleteAdvertisementModal] = React.useState<boolean>(false)
    const [isError, setIsError] = React.useState<boolean>(false)
    const [noImagesClassName, setNoImagesClassName] = React.useState<string>('')
    const slideshowContainerRef = React.useRef(null)
    const isMobileOrTablet = useMediaQuery('(max-width: 899px)')
    // params, navigate
    const params = useParams()
    const {id} = params
    const navigate = useNavigate()
    // context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)

    // actions
    const getAdvertisementAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl(`/api/advertisement/${id}`),
        headers: {
            Accept: 'application/json'
        },
    }

    const deleteDocumentAction: Action = {
        method: 'DELETE',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/advertisement/document/delete/`),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken ?? ''}`,
            Accept: 'application/json'
        }
    }

    const deleteAdvertisementAction: Action = {
        method: 'DELETE',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/advertisement/delete/${id}`),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        }
    }

    React.useEffect(() => {
        if (advertisement !== null && isMobileOrTablet) {
            return
        }
        if (advertisement !== null && (advertisement.photographs.length === 0 && advertisement.documents.length === 0)) {
            setNoImagesClassName('height-25')
        } else if (advertisement !== null && (advertisement.photographs.length === 0 && advertisement.documents.length > 0)) {
            setNoImagesClassName('height-15')
        }
    }, [advertisement])

    React.useEffect(() => {
        const getAdvertisement = async (): Promise<void> => {
            setIsLoading(true)
            const {payload, error} = await request(getAdvertisementAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                setAdvertisement(payload)
            }
            setIsLoading(false)
        }
        getAdvertisement()
    }, [id])

    const handlePhotoClick = (index: number) => {
        setActivePhotoIndex(index)
    }

    const handleScrollUp = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
        }
    }

    const handleScrollDown = () => {
        if (advertisement !== null && advertisement.photographs !== null) {
            if (startIndex + visibleThumbnails < advertisement.photographs.length) {
                setStartIndex(startIndex + 1);
            }
        }
    }

    const handleShare = () => {
        const currentUrl = window.location.href
        // check if navigator.share is supported by the browser
        if (!navigator.share) {
            navigator.clipboard.writeText(currentUrl)
            toast.success('Odkaz na ponuku bol skopírovaný do schránky!')
        } else {
            navigator.share({url: currentUrl})
        }
    }

    const handleLightboxClose = () => {
        setLightboxOpen(false);
    }

    const handlePhotographModalHideAndClearModalPhotograph = (): void => {
        setPhotographToDelete(null)
        setPhotographDeleteModalShow(false)
    }

    const handleDeletePhotograph = async (photographToDeleteId: number) => {
        const handleDeletePhotographAction2 = {
            ...deleteDocumentAction,
            endpoint: deleteDocumentAction.endpoint + photographToDeleteId
        }
        setIsLoading(true)
        const {payload, status, error} = await request(handleDeletePhotographAction2, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        } else {
            if (status === 204) {
                const updatedPhotographs = advertisement?.photographs?.filter((photograph) => photograph.id !== photographToDeleteId) || []
                // updatneme state
                setAdvertisement((prevAdvertisement) => ({
                    ...prevAdvertisement!,
                    photographs: updatedPhotographs
                }))
                toast.success(PHOTOGRAPH_SUCCESSFULLY_DELETED)
                // vycistime modal
                handlePhotographModalHideAndClearModalPhotograph()
            } else {
                toast.error(payload.errors.delete)
            }
        }
        if (status === 401) {
            //- user sa nedokazal overit v api - t.j. odhlasime ho
            await authContext.refreshJWTToken()
        }
        setIsLoading(false)
    }

    const handleAskDeletePhotographQuestion = (e: React.MouseEvent<HTMLButtonElement>, photograph: DocumentInterface) => {
        e.stopPropagation()
        setPhotographToDelete(photograph)
        setPhotographDeleteModalShow(true)
    }

    const handleDownloadDocument = (documentItem: DocumentInterface): void => {
        // create a element
        const link = document.createElement('a')
        // set href to point to base64
        link.href = getApiUrlFromRelativeUrl(documentItem.url ?? '')
        // nastavime name ktory sa userovi stiahne - s takymto nazvom sa mu dokument zobrazi v stiahnutych suboroch...(name ma na sebe aj priponu)
        link.download = documentItem.name ?? ''
        // click
        link.click()
        // revoke the url
        URL.revokeObjectURL(link.href)
        // remove element
        link.remove()
    }

    const askDeleteDocumentQuestion = (documentItem: DocumentInterface): void => {
        setDocumentItemToDelete(documentItem)
        setDocumentItemDeleteModalShow(true)
    }

    const handleDocumentItemModalHideAndClearModalDocumentItem = (): void => {
        setDocumentItemToDelete(null)
        setDocumentItemDeleteModalShow(false)
    }

    const handleDeleteDocument = async (documentItemToDeleteId: number) => {
        const handleDeleteDocumentAction2 = {
            ...deleteDocumentAction,
            endpoint: deleteDocumentAction.endpoint + documentItemToDeleteId
        }
        setIsLoading(true)
        const {payload, error, status} = await request(handleDeleteDocumentAction2, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        } else {
            if (status === 204) {
                const updatedDocuments = advertisement?.documents?.filter((document) => document.id !== documentItemToDeleteId) || []
                // updatneme state
                setAdvertisement((prevAdvertisement) => ({
                    ...prevAdvertisement!,
                    documents: updatedDocuments
                }))
                toast.success(DOCUMENT_SUCCESSFULLY_DELETED)
                // vycistime modal
                handleDocumentItemModalHideAndClearModalDocumentItem()
            } else {
                toast.error(payload.errors.delete)
            }
        }
        if (status === 401) {
            //- user sa nedokazal overit v api - t.j. odhlasime ho
            await authContext.refreshJWTToken()
        }
        setIsLoading(false)
    }

    const handleEditAdvertisement = (): void => {
        if (authContext.userLogged === null || !authContext.userLogged?.canEditOtherAdvertisements) {
            toast.error(CANNOT_EDIT_ADVERTISEMENT)
            return
        }
        navigate(`/advertisement/edit/${id}`)
        window.scrollTo(0, 0)
    }

    const handleDeleteAdvertisement = (): void => {
        if (authContext.userLogged === null || !authContext.userLogged?.canDeleteOtherAdvertisements) {
            toast.error(CANNOT_DELETE_ADVERTISEMENT)
            return
        }
        setShowDeleteAdvertisementModal(true)
    }

    const handleDeleteAdvertisement2 = async (): Promise<void> => {
        if (authContext.userLogged === null || !authContext.userLogged?.canDeleteOtherAdvertisements) {
            toast.error(CANNOT_DELETE_ADVERTISEMENT)
            return
        }
        setIsLoading(true)
        const {payload, error, status} = await request(deleteAdvertisementAction, clientContext)
        if (error && status === 500) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        } else {
            if (payload.success) {
                toast.success(DELETE_ADVERTISEMENT_SUCCESS_MESSAGE)
                navigate('/advertisements')
                window.scrollTo(0, 0)
            }
        }
        if (status === 401) {
            await authContext.refreshJWTToken()
        }
        setIsLoading(false)
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            {/* delete photograph modal */}
            <ConfirmModal
                title='Zmazať fotografiu'
                message='Naozaj chcete zmazať túto fotografiu? Táto akcia je nevratná.'
                show={photographDeleteModalShow}
                onCloseCallback={handlePhotographModalHideAndClearModalPhotograph}
                onSuccessCallback={() => handleDeletePhotograph(photographToDelete?.id as number)}
            />
            {/* document delete modal */}
            <ConfirmModal
                show={documentItemDeleteModalShow}
                onCloseCallback={handleDocumentItemModalHideAndClearModalDocumentItem}
                onSuccessCallback={() => handleDeleteDocument(documentItemToDelete?.id as number)}
                title='Zmazať dokument'
                message='Naozaj chcete zmazať tento dokument? Táto akcia je nevratná.'
            />
            <ConfirmModal
                show={showDeleteAdvertisementModal}
                onCloseCallback={() => setShowDeleteAdvertisementModal(false)}
                onSuccessCallback={() => handleDeleteAdvertisement2()}
                title='Zmazať inzerát'
                message='Naozaj chcete zmazať tento inzerát? Táto akcia je nevratná.'
            />
            <Container sx={{mt: '2%', mb: '5%', height: 'auto'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} sx={{mt: '7.5%'}}>
                        <Card
                            sx={{border: 'none', boxShadow: 'none', position: 'sticky', top: '50px'}}>
                            <CardContent className='grey-background'
                                         style={{borderRadius: '15px'}}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <p className='text-primary' style={{fontFamily: 'OpenSansSemiBold'}}>
                                        {advertisement?.isVPK ? 'Vyvolávacia cena' : 'Najnižšie podanie'}
                                    </p>
                                    <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                        <strong>
                                            {advertisement !== null && advertisement?.startingPrice !== null ? (
                                                <span>
                                                    {advertisement?.startingPrice?.price?.toFixed(2)} {advertisement?.startingPrice?.currency}
                                                </span>
                                            ) : (
                                                <span>
                                                  Na vyžiadanie
                                              </span>
                                            )}
                                        </strong>
                                    </p>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                        Zábezpeka
                                    </p>
                                    <p style={{fontFamily: 'OpenSansRegular', marginLeft: '5%'}}>
                                        {advertisement?.depositPrice === null ? (
                                            <span style={{wordBreak: 'break-word'}}>
                                                {advertisement?.depositPriceString}
                                            </span>
                                        ) : (
                                            <span>
                                                {advertisement?.depositPrice?.price?.toFixed(2)} {advertisement?.depositPrice?.currency}
                                            </span>
                                        )}
                                    </p>
                                </Box>
                                <hr/>
                                <Box display='flex' alignItems='center' justifyContent='space-between'>
                                    <p className='text-primary' style={{fontFamily: 'OpenSansSemiBold'}}>
                                        {advertisement?.isVPK ? 'Lehota na predkladanie ponúk' : 'Dátum dražby'}
                                    </p>
                                    <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                        {advertisement?.dateOfProceeding !== null ? dayjs(advertisement?.dateOfProceeding, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm') : ''}
                                    </p>
                                </Box>
                                <Box display='flex' alignItems='center' justifyContent='space-between'>
                                    <p className='text-primary' style={{fontFamily: 'OpenSansSemiBold'}}>
                                        {advertisement?.isVPK ? 'Predkladanie ponúk' : 'Miesto dražby'}
                                    </p>
                                    <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                        {getSubject(`${advertisement?.placeOfProceedingString ?? ''}`).includes('...') ? (
                                            <Tooltip title={`${advertisement?.placeOfProceedingString ?? ''}`}>
                                            <span>
                                                {getSubject(`${advertisement?.placeOfProceedingString ?? ''}`)}
                                            </span>
                                            </Tooltip>
                                        ) : (
                                            <span>
                                            {getSubject(`${advertisement?.placeOfProceedingString ?? ''}`)}
                                        </span>
                                        )}
                                    </p>
                                </Box>
                                <hr/>
                                <Box display='flex' alignItems='center' justifyContent='space-between'>
                                    <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                        1. Obhliadka
                                    </p>
                                    <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                        {advertisement?.firstInspection?.dateFrom !== null && advertisement?.firstInspection?.dateFrom !== undefined ? dayjs(advertisement?.firstInspection?.dateFrom, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm') : ''}
                                    </p>
                                </Box>
                                <Box display='flex' alignItems='center' justifyContent='space-between'>
                                    <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                        2. Obhliadka
                                    </p>
                                    <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                        {advertisement?.secondInspection?.dateFrom !== null && advertisement?.secondInspection?.dateFrom !== undefined ? dayjs(advertisement?.secondInspection?.dateFrom, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm') : ''}
                                    </p>
                                </Box>
                                {advertisement?.showAdvertisementUserData && (
                                    <React.Fragment>
                                        <hr/>
                                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                                            <p className='text-primary' style={{fontFamily: 'OpenSansSemiBold'}}>
                                                Organizátor
                                            </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                                <img
                                                    src={((advertisement?.advertisementUser !== null && advertisement?.advertisementUser !== undefined) && (advertisement?.advertisementUser.avatar !== null && advertisement?.advertisementUser.avatar !== undefined)) ? advertisement?.advertisementUser?.avatar?.url !== null && advertisement?.advertisementUser?.avatar?.url !== undefined ? getApiUrlFromRelativeUrl(advertisement?.advertisementUser?.avatar?.url): my_profile_photo : my_profile_photo}
                                                    alt="avatar" width={32} height={32}
                                                    style={{
                                                        marginRight: '5px',
                                                        border: '1px solid #fff',
                                                        borderRadius: '50%',
                                                        objectFit: 'contain'
                                                    }}/>
                                                {advertisement?.advertisementUser?.url !== null && advertisement?.advertisementUser?.url !== undefined ? (
                                                    <a href={`${advertisement?.advertisementUser?.url.includes('https://') ? advertisement?.advertisementUser?.url : `https://${advertisement?.advertisementUser?.url}`}`}
                                                       target='_blank'>
                                                        {advertisement?.advertisementUser?.isFO ? ((advertisement?.advertisementUser?.title ?? '')  + ' ' + advertisement?.advertisementUser?.firstName + ' ' + advertisement?.advertisementUser?.lastName) : advertisement?.advertisementUser?.nameOfCompany}
                                                    </a>
                                                ) : (
                                                    <span>
                                                        {advertisement?.advertisementUser?.isFO ? ((advertisement?.advertisementUser?.title ?? '') + ' ' + advertisement?.advertisementUser?.firstName + ' ' + advertisement?.advertisementUser?.lastName) : advertisement?.advertisementUser?.nameOfCompany}
                                                    </span>
                                                )}
                                            </p>
                                        </Box>
                                    </React.Fragment>
                                )}
                                <Box>
                                    <p style={{fontFamily: 'OpenSansBold', fontSize: '14px'}}>
                                        <strong>
                                            Máte záujem?
                                        </strong>
                                    </p>
                                </Box>
                                <Box>
                                    <p style={{fontFamily: 'OpenSansRegular', fontSize: '14px'}}>
                                        Dajte nám vaše kontaktné informácie a my sa Vám ozveme.
                                    </p>
                                </Box>
                                <AdvertisementContactPersonForm
                                    autoCheckCheckbox={true}
                                    advertisementId={parseInt(id as string)}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={9} style={{height: '100%'}}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <h1 className='text-primary' style={{fontFamily: 'OpenSansSemiBold'}}>
                                    {advertisement?.subject}
                                </h1>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {authContext.userLogged !== null && authContext.userLogged.canEditOtherAdvertisements && (
                                    <Tooltip title='Upraviť inzerát'>
                                        <IconButton aria-label="Upraviť inzerát"
                                                    onClick={() => handleEditAdvertisement()}>
                                            <EditIcon className='icon'/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {authContext.userLogged !== null && authContext.userLogged.canDeleteOtherAdvertisements && (
                                    <Tooltip title="Zmazať inzerát">
                                        <IconButton aria-label="Zmazať inzerát"
                                                    onClick={() => handleDeleteAdvertisement()}
                                        >
                                            <DeleteIcon className='icon'/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                        <div style={{marginTop: '3%'}} className={noImagesClassName}>
                            {advertisement !== null && advertisement.photographs.length === 0 && (
                                <div className='grey-background rounded-borders'
                                     style={{width: '100%', height: '100%'}}>
                                    <div className="text-center" style={{height: '100%', width: '100%'}}>
                                        <img
                                            src={
                                                advertisement.thingType === AdvertisementThingType.MOVABLE ? auta_kategoria : advertisement.thingType === AdvertisementThingType.IMMOVABLE ? nehnutelne_veci : iny_majetok_modre
                                            }
                                            className={`showAdvertisementisIne`}
                                            alt='Žiadne fotografie'
                                            style={{height: '100%', width: '100%'}}
                                        />
                                    </div>
                                </div>
                            )}
                            {advertisement !== null && advertisement.photographs.length > 0 && (
                                <div ref={slideshowContainerRef} style={{height: '695px', position: 'relative'}}>
                                    <Grid container spacing={2} className="slideshow" style={{height: '100%'}}>
                                        <Grid item xs={3} style={{height: '100%', maxHeight: '695px'}}>
                                            <Grid
                                                container
                                                direction="column"
                                                style={{height: '100%', marginBottom: '32px', paddingRight: '15px'}}
                                                className="slideshow-scrollbar"
                                            >
                                                <Grid item style={{
                                                    display: startIndex > 0 ? 'flex' : 'none',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <IconButton onClick={handleScrollUp} style={{
                                                        width: '100%',
                                                        backdropFilter: 'blur(8px)',
                                                        background: 'rgba(255, 255, 255, 0.8)'
                                                    }}>
                                                        <KeyboardArrowUpIcon/>
                                                    </IconButton>
                                                </Grid>
                                                <Grid item style={{flex: 1}}>
                                                    <Grid container direction="column"
                                                          style={{marginRight: '-15px', paddingRight: '15px'}}>
                                                        {advertisement.photographs.slice(startIndex, startIndex + visibleThumbnails).map((photo: DocumentInterface, index: number) => (
                                                            <Grid item key={index}
                                                                  onClick={() => handlePhotoClick(startIndex + index)}>
                                                                <div style={{position: 'relative'}}>
                                                                    <img
                                                                        src={photo.url !== null && photo.url !== undefined ? getApiUrlFromRelativeUrl(photo.url) : ''}
                                                                        alt={`Photo ${startIndex + index}`}
                                                                        className={startIndex + index === activePhotoIndex ? 'active thumbnail' : 'thumbnail'}
                                                                        style={{
                                                                            width: '100%',
                                                                            objectFit: 'cover',
                                                                            cursor: 'pointer',
                                                                            borderRadius: '15px',
                                                                            marginBottom: '5px'
                                                                        }}
                                                                    />
                                                                    {advertisement?.advertisementUser?.id === authContext.userLogged?.id && (
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            top: '-10px',
                                                                            right: '-18px'
                                                                        }}>
                                                                            <Tooltip title='Zmazať fotografiu'>
                                                                                <IconButton
                                                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleAskDeletePhotographQuestion(e, photo)}
                                                                                >
                                                                                    <FaTimesCircle
                                                                                        style={{
                                                                                            fontSize: '18px',
                                                                                            zIndex: 10
                                                                                        }}
                                                                                        className='icon-primary clickable'
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{
                                                    display: startIndex + visibleThumbnails < advertisement.photographs.length ? 'flex' : 'none',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <IconButton onClick={handleScrollDown} style={{
                                                        width: '100%',
                                                        backdropFilter: 'blur(8px)',
                                                        background: 'rgba(255, 255, 255, 0.8)'
                                                    }}>
                                                        <KeyboardArrowDownIcon/>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9} style={{position: 'relative'}}>
                                            <img
                                                src={advertisement.photographs[activePhotoIndex].url !== null && advertisement.photographs[activePhotoIndex].url !== undefined ? getApiUrlFromRelativeUrl(advertisement.photographs[activePhotoIndex].url ?? '') : ''}
                                                alt={`Active Photo`}
                                                className="active-photo"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    maxHeight: '695px',
                                                    objectFit: 'cover',
                                                    borderRadius: '15px',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => setLightboxOpen(true)}
                                            />
                                            <button
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '10px',
                                                    right: '10px',
                                                    zIndex: 1,
                                                    fontFamily: 'OpenSansBold',
                                                    fontSize: '23px'
                                                }}
                                                className='background-primary rounded-borders border-primary'
                                                onClick={handleShare}
                                            >
                                                <Share/>
                                                <span style={{marginLeft: '5px'}}>
                                                    Zdielať inzerát
                                                </span>
                                            </button>
                                            {lightboxOpen && (
                                                <div
                                                    style={{
                                                        position: 'fixed',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        zIndex: 9999
                                                    }}
                                                    onClick={handleLightboxClose}
                                                >
                                                    <img
                                                        src={advertisement.photographs[activePhotoIndex].url !== null && advertisement.photographs[activePhotoIndex].url !== undefined ? getApiUrlFromRelativeUrl(advertisement.photographs[activePhotoIndex].url ?? '') : ''}
                                                        alt={advertisement.photographs[activePhotoIndex].name || ''}
                                                        style={{
                                                            maxHeight: '80%',
                                                            maxWidth: '80%',
                                                            borderRadius: '15px'
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </div>
                        <div style={{marginTop: '2%', position: 'relative'}}>
                            {advertisement !== null && advertisement.documents.length > 0 && (
                                <React.Fragment>
                                    <Typography variant='h5' component='div'
                                                style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                                        Prílohy
                                    </Typography>
                                    <Carousel
                                        swipeable={true}
                                        draggable={true}
                                        infinite={false}
                                        arrows={false}
                                        showDots={true}
                                        responsive={{
                                            desktop: {
                                                breakpoint: {max: 3000, min: 1024},
                                                items: 4,
                                                slidesToSlide: 4 // optional, default to 1.
                                            },
                                            tablet: {
                                                breakpoint: {max: 1024, min: 464},
                                                items: 2,
                                                slidesToSlide: 2 // optional, default to 1.
                                            },
                                            mobile: {
                                                breakpoint: {max: 464, min: 0},
                                                items: 1,
                                                slidesToSlide: 1 // optional, default to 1.
                                            }
                                        }}
                                    >
                                        {advertisement.documents.map((document: DocumentInterface) => (
                                            <div style={{
                                                marginRight: '15px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '250px', // Set the height to match the carousel height
                                                width: '200px',
                                                overflow: 'visible',
                                            }} key={document.id}>
                                                <Card style={{
                                                    border: 'none',
                                                    width: '180px',
                                                    height: '180px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: '#1851AB',
                                                    borderRadius: '10px',
                                                    position: 'relative',
                                                    overflow: 'visible',
                                                }}>
                                                    <Container>
                                                        <Typography variant='body1' align='center'
                                                                    style={{color: 'white'}}>
                                                            <span style={{textTransform: 'uppercase'}}>
                                                                {document.extension}
                                                            </span>
                                                            &nbsp;dokument
                                                        </Typography>
                                                        <div className="rounded-borders" style={{
                                                            backgroundColor: 'white',
                                                            marginTop: '15px',
                                                            width: '100%',
                                                            padding: '5px'
                                                        }}>
                                                            <Typography variant='body1' align='center'
                                                                        className='text-primary'
                                                                        style={{fontFamily: 'OpenSansBold'}}>
                                                                {getDocumentName(document.name).indexOf('...') !== -1 ? (
                                                                    <Tooltip title={document.name}>
                                                                        <span>
                                                                            {getDocumentName(document.name)}
                                                                        </span>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <span>
                                                                        {document.name}
                                                                    </span>
                                                                )}
                                                            </Typography>
                                                        </div>
                                                        <Box display="flex" alignItems="start"
                                                             justifyContent="space-between">
                                                            <Box display='flex' alignItems='start'>
                                                                <Tooltip title='Stiahnuť dokument'>
                                                                    <IconButton aria-label="Stiahnuť dokument"
                                                                                onClick={() => handleDownloadDocument(document)}>
                                                                        <DownloadForOfflineIcon
                                                                            className='icon-white'/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {advertisement?.advertisementUser?.id === authContext.userLogged?.id && (
                                                                    <Tooltip title="Zmazať dokument">
                                                                        <IconButton aria-label="Zmazať dokument"
                                                                                    onClick={() => askDeleteDocumentQuestion(document)}
                                                                        >
                                                                            <DeleteIcon className='icon-white'/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Container>
                                                </Card>
                                            </div>
                                        ))}
                                    </Carousel>
                                </React.Fragment>
                            )}
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Card sx={{border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                                    <CardContent className='background-primary' style={{borderRadius: '15px'}}>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansSemiBold', fontSize: '13px'}}>
                                                Predajná cena
                                            </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansBold', fontSize: '23px'}}>
                                                {advertisement?.startingPrice?.price?.toFixed(2)} {advertisement?.startingPrice?.currency}
                                            </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansRegular', fontSize: '13px'}}>
                                                Zábezpeka
                                            </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansRegular', fontSize: '18px'}}>
                                                {advertisement?.depositPrice?.price?.toFixed(2)} {advertisement?.depositPrice?.currency}
                                            </p>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card sx={{border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                                    <CardContent className='background-primary'
                                                 style={{borderRadius: '15px'}}>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansSemiBold', fontSize: '13px'}}>
                                                {advertisement?.isVPK ? 'Lehota na predkladanie ponúk' : 'Dátum dražby'}
                                            </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansBold', fontSize: '23px'}}>
                                                {advertisement?.dateOfProceeding !== null ? dayjs(advertisement?.dateOfProceeding, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm') : ''}
                                            </p>
                                        </Box>
                                        <hr/>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansRegular', fontSize: '13px'}}>
                                                1. Obhliadka
                                            </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansRegular', fontSize: '23px'}}>
                                                {advertisement?.firstInspection?.dateFrom !== null && advertisement?.firstInspection?.dateFrom !== undefined ? dayjs(advertisement?.firstInspection?.dateFrom, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm') : ''}
                                            </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansRegular', fontSize: '13px'}}>
                                                2. Obhliadka
                                            </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansRegular', fontSize: '23px'}}>
                                                {advertisement?.secondInspection?.dateFrom !== null && advertisement?.secondInspection?.dateFrom !== undefined ? dayjs(advertisement?.secondInspection?.dateFrom, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm') : ''}
                                            </p>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card sx={{border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                                    <CardContent className='background-primary'
                                                 style={{borderRadius: '15px'}}>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansSemiBold', fontSize: '13px'}}>
                                                {advertisement?.isVPK ? 'Predkladanie ponúk' : 'Miesto dražby'}
                                            </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansBold', fontSize: '23px'}}>
                                                {advertisement?.placeOfProceedingString}
                                            </p>
                                        </Box>
                                        <hr/>
                                        <Box>
                                            <p style={{fontFamily: 'OpenSansRegular', fontSize: '13px'}}>
                                                {advertisement?.address}
                                            </p>
                                            <p style={{fontFamily: 'OpenSansRegular', fontSize: '13px'}}>
                                                {advertisement?.city?.name} {advertisement?.houseNumber}
                                            </p>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={12}>
                                <Box>
                                    <strong>
                                        Predmet {advertisement?.isVPK ? 'verejného ponukového konania' : 'dražby'}
                                    </strong>
                                </Box>
                                <Box sx={{maxWidth: '100%'}}>
                                    <div className='w-100'
                                         dangerouslySetInnerHTML={{__html: advertisement?.subjectOfAdvertisement ?? ''}}></div>
                                </Box>
                                <hr/>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item xs={12} md={12}>
                                <Box>
                                    <strong>
                                        Opis
                                    </strong>
                                </Box>
                                <Box sx={{maxWidth: '100%'}}>
                                    <div className='w-100'
                                         dangerouslySetInnerHTML={{__html: advertisement?.description?.replaceAll("\n", '<br>') ?? ''}}></div>
                                </Box>
                                {(advertisement !== null && (advertisement.youtubeVideoEmbed !== null && advertisement.youtubeVideoEmbed !== '')) && (
                                    <React.Fragment>
                                        <hr/>
                                        <Box sx={{maxWidth: '100%'}}>
                                            <div className='w-100'
                                                 dangerouslySetInnerHTML={{__html: advertisement?.youtubeVideoEmbed ?? ''}}></div>
                                        </Box>
                                    </React.Fragment>
                                )}
                                <div className='w-100' style={{marginBottom: '3%'}}>
                                    <LeafletMap
                                        advertisementId={parseInt(id as string)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {advertisement?.advertisementUser?.id === authContext.userLogged?.id && (
                    <React.Fragment>
                    <Typography variant='h5' component='div' className='text-primary'
                                    sx={{fontFamily: 'OpenSansBold', mt: '2%'}}>
                            Balíky
                        </Typography>
                        <div className="w-100" style={{marginTop: '2%'}}>
                            <Packages advertisementId={parseInt(id as string)}
                                      userId={authContext.userLogged?.id ?? 0}
                                      jwtToken={authContext.jwtToken ?? ''}
                            />
                        </div>
                    </React.Fragment>
                )}
            </Container>
            <ToppedAdvertisements marginBottom='7%'/>
        </React.Fragment>
    )
}
